import React, {useEffect, useState} from "react"
import {Button, Spinner} from "react-bootstrap"
import {useTranslation} from "react-i18next"
import {InfoCircleFill} from 'react-bootstrap-icons';
import {setCharAt} from "../../helpers/helperfunctions.js"
import {cloneDeep} from "lodash"
import {validateParameter} from "../../helpers/settingsHelper.js"
import {getBorderErrorOrWarningClassFromMessage} from "../../helpers/uiHelper.js"
import PopOverDialog from "../shared/popoverdialog/PopOverDialog.js";
import {readOnlyParamsList} from "../../helpers/constants.js";
import {isAdmin} from "../../helpers/authHelper.js";
import {useMsal} from "@azure/msal-react";

/** @param {function(Object, boolean)} promptForSave
 * @param {object} selectedParameter
 * @param {boolean} isAware
 * @param {object} dynamicParamSpec
 * @returns {Element}
 * @constructor
 */
export function DeviceParameterContent({promptForSave, selectedParameter, isAware = false, dynamicParamSpec = null, device = null}) {
    const {t} = useTranslation(['parameters', 'common'])
    const [model, setModel] = useState({value: getValue()})
    const {accounts} = useMsal();

    useEffect(() => {
        setModel({value: getValue()})
    }, [selectedParameter])


    function isDeviceCommunicationMaster() {
        return device?.device_twin.reported?.ethernetViaDeviceSerial === device?.serial_number
    }

    function isDeviceGroupMaster() {
        return !device?.slave
    }

    function isReadOnly(selectedParameter) {
        if(!selectedParameter) return true
        if(!isAdmin(accounts) && readOnlyParamsList.includes(selectedParameter.id.toString())) return true
        if(isAware) return false
        if(device === null) return false
        if(selectedParameter.scope === 'GLOBAL') {
            return !isDeviceCommunicationMaster()
        }else if(selectedParameter.scope === 'GROUP') {
            return !isDeviceGroupMaster()  
        }else {
            return false
        }
    }

    function getValue() {
        if (!selectedParameter) return ""
        if (selectedParameter.type === 'ip_address_half') {
            return selectedParameter.value.split(".").map(e => parseInt(e))
        }
        return selectedParameter.value
    }

    function prepareData() {
        switch (selectedParameter.type) {
            case 'ip_address_half':
                return { // Ip addresses are creatively stored in two subsequent rows
                    [selectedParameter.id]: model.value[0] * 256 + model.value[1],
                    [selectedParameter.id + 1]: model.value[2] * 256 + model.value[3]
                }
            case 'decimal':
                if (isAware) {
                    return {
                        [selectedParameter.id]: parseFloat(model.value)
                    }
                }
                break;
            case 'int':
                if (isAware) {
                    return {
                        [selectedParameter.id]: parseInt(model.value)
                    }
                }
                break;
            case 'bool':
                if (isAware) {
                    return {
                        [selectedParameter.id]: Boolean(model.value)
                    }
                }
        }

        return {
            [selectedParameter.id]: model.value
        }
    }

    function validate() {
        return validateParameter(selectedParameter.id, model, setModel, t, [], dynamicParamSpec)
    }

    function validateThenPrepareDataAndPrompt(saveForGroup) {
        if (!validate()) return

        const data = prepareData()
        console.log(data)
        promptForSave(data, saveForGroup)
    }

    function ContentItem({label, value}) {
        return <div className='d-flex mb-3'>
            <span className='text-bold' style={{width: "150px", fontSize: '17px'}}>{label}:</span>
            <span style={{flex: '1'}}>{value}</span>
        </div>
    }

    return <div className='boxstyling p-5 p-xs-2 mt-xs-2' style={{flex: '1'}}>
        {selectedParameter &&
            <>
                <h4 className='mb-3'>{selectedParameter.name_orig}</h4>
                <div style={{flex: '1'}}>
                    <ContentItem label={'ID'} value={selectedParameter.id}/>

                    {/* === Input ele === */}
                    <div className='d-flex mb-3'>
                        <span className='text-bold' style={{width: "150px", fontSize: '17px'}}>Value:</span>
                        <div style={{flex: '1'}}>
                            <ValueElem isReadOnly={isReadOnly} selectedParameter={selectedParameter}
                                       model={model} setModel={setModel} t={t}/>
                        </div>
                    </div>

                    <ContentItem label={'Type'} value={selectedParameter.type}/>
                    {(selectedParameter.type === 'int' || selectedParameter.type === 'decimal') && <>
                        <ContentItem label={'Default Value'} value={selectedParameter.default}/>
                        <ContentItem label={'Min Value'} value={selectedParameter.min}/>
                        <ContentItem label={'Max Value'} value={selectedParameter.max}/>
                        {selectedParameter.res &&
                            <ContentItem label={'Resolution'} value={selectedParameter.res}/>
                        }
                    </>}
                    {selectedParameter?.desc &&
                        <ContentItem label={'Description'}
                                     value={selectedParameter.desc.toString().split('\n')?.map((descLine, idx) =>
                                         <div className='mb-1' key={idx}>{descLine.trim()}</div>) ?? ""}/>
                    }
                    {selectedParameter?.messages?.length > 0 && selectedParameter.messages.map((object, idx) =>
                        <div className={`color-${object.type} d-flex mt-3`} key={idx}>
                            <Spinner className='me-2' animation="border" size={"sm"}/>{object.text}
                        </div>)}
                    {model.messages && model.messages.map((object, i) =>
                        <div className={`color-${object.type}`} key={i}>{object.text}</div>)
                    }
                </div>
               
                {isReadOnly(selectedParameter) && selectedParameter.scope === 'GLOBAL' && !isDeviceCommunicationMaster() &&
                    <div className="alert alert-info">
                        <InfoCircleFill className='me-1 mb-1 text-primary'/>{t('settingspage:parameter-communication-master-device')}
                        {device.master_serial_number &&
                            <a href={"/device/" + device.master_serial_number + "/parameters"}>{device.master_serial_number}</a>}
                    </div>     
                }

                {isReadOnly(selectedParameter) && selectedParameter.scope === 'GROUP' && !isDeviceGroupMaster() &&
                    <div className="alert alert-info">
                        <InfoCircleFill className='me-1 mb-1 text-primary'/>{t('settingspage:parameter-group-master-device')}
                        {device?.device_twin.reported?.ethernetViaDeviceSerial &&
                            <a href={"/device/" + device?.device_twin.reported?.ethernetViaDeviceSerial + "/parameters"}>{device?.device_twin.reported?.ethernetViaDeviceSerial}</a>}
                    </div>     
                }

                <div className='d-flex p-2 justify-content-end'>
                    <Button variant='outline-secondary' disabled={isReadOnly(selectedParameter)}
                            onClick={() => validateThenPrepareDataAndPrompt(false)}
                            className='me-2'>
                        {t('settingspage:save_device')}</Button>
                    <Button variant='outline-secondary' disabled={isReadOnly(selectedParameter)}
                            onClick={() => validateThenPrepareDataAndPrompt(true)}>
                        {t('settingspage:save_group')}</Button>
                </div>
            </>
        }
    </div>
}

function ValueElem({isReadOnly, selectedParameter, model, setModel, t}) {
    function setIpAddressValue(v, idx) {
        const newVal = cloneDeep(model.value)
        newVal[idx] = parseInt(v)
        setModel({value: newVal})
    }

    if (isReadOnly(selectedParameter)) return <div>{model.value}
        <PopOverDialog popOverTitel={t('common:readonly_parameter_info.title')}
                       popOverContent={t('common:readonly_parameter_info.text')}
                       trigger={['hover', 'touch']} contentAsHtml={true}/>
    </div>
    if (selectedParameter.type === 'string') return <div>{model.value}</div>
    if (selectedParameter.type === 'bool') {
        return <ul style={{listStyleType: 'none', lineHeight: '30px', paddingInlineStart: '0px', flex: '1'}}>
            <li>
                <input id={'true'} type="radio" checked={model.value} className='cursor-pointer'
                       onChange={() => setModel({value: true})}
                       name="radio"/>
                <label className='ps-2 cursor-pointer' htmlFor={'true'}>True</label>
            </li>
            <li>
                <input id={'false'} type="radio" checked={!model.value} className='cursor-pointer'
                       onChange={() => setModel({value: false})}
                       name="radio"/>
                <label className='ps-2 cursor-pointer' htmlFor={'false'}>False</label>
            </li>
        </ul>
    }
    if (selectedParameter.type === 'ip_address_half') {
        return <div style={{flex: '1'}} className='d-flex'>
            <input style={{width: "6ch"}} onChange={(e) => setIpAddressValue(e.target.value, 0)}
                   type='number' min={0} max={255} value={model.value[0]}/>
            <span className='mx-1'>.</span>
            <input style={{width: "6ch"}} onChange={(e) => setIpAddressValue(e.target.value, 1)}
                   type='number' min={0} max={255} value={model.value[1]}/>
            <span className='mx-1'>.</span>
            <input style={{width: "6ch"}} onChange={(e) => setIpAddressValue(e.target.value, 2)}
                   type='number' min={0} max={255} value={model.value[2]}/>
            <span className='mx-1'>.</span>
            <input style={{width: "6ch"}} onChange={(e) => setIpAddressValue(e.target.value, 3)}
                   type='number' min={0} max={255} value={model.value[3]}/>
        </div>
    }
    if (selectedParameter.type === 'int' || selectedParameter.type === 'decimal') {
        return <div style={{flex: '1'}}>
            <input
                className={`form-control shadow-none remove-autofill-overlay d-inline-block ${getBorderErrorOrWarningClassFromMessage(model.messages)}`}
                style={{width: selectedParameter.res ? `${selectedParameter.max.toString().length + (selectedParameter.res == 1e-10 ? 10 : selectedParameter.res.toString().length) + 6}ch` : '10ch'}}
                onChange={(e) => setModel({value: e.target.value})} type='number'
                step={selectedParameter.res ?? undefined} value={model.value}
            />
            <span className='ms-1'>{selectedParameter.unit}</span>
        </div>
    }
    if (selectedParameter.type === 'enum') {
        return <ul style={{
            listStyleType: 'none',
            lineHeight: '30px',
            paddingInlineStart: '0px',
            flex: '1'
        }}>
            {Object.entries(selectedParameter.options).map(([k, v]) => <li key={k}>
                <input id={k} type="radio" checked={k == model.value} className='cursor-pointer'
                       onChange={(event) => setModel({value: parseInt(event.target.value)})}
                       value={k}
                       name="radio"/>
                <label className='ps-2 cursor-pointer' htmlFor={k}>{v}</label>
            </li>)}
        </ul>
    }
    if (selectedParameter.type === 'bitmask') {
        return <ul style={{
            listStyleType: 'none',
            lineHeight: '30px',
            paddingInlineStart: '0px',
            flex: '1'
        }}>
            {selectedParameter.options.map((item, idx) => <li key={idx}>
                <input id={idx} type="checkbox" checked={'1' == model.value[idx]}
                       className='cursor-pointer'
                       onChange={() => setModel({value: setCharAt(model.value, idx, model.value[idx] == '1' ? '0' : '1')})}
                       value={idx} name="radio"/>
                <label className='ps-2 cursor-pointer' htmlFor={idx}>{item.name_orig}</label>
            </li>)}
        </ul>
    }
}
