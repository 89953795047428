import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {AnyReportedParameters, isAwareDevice} from "../../../helpers/helperfunctions.js";
import {useDispatch, useSelector} from "react-redux";
import NavTopBar from "../../../components/shared/device/NavTopBar";
import {useTranslation} from "react-i18next";
import {getDeviceParamsStateStructure} from "../../../components/parameters/getDeviceParamsStateStructure.js";
import {
    saveConfigurationByDeviceList,
    saveConfigurationByGroupList
} from "../../../helpers/reduxstore/reducers/groupReducer.js";
import SaveForGroupsModal from "../../../components/settings/saveforgroupsmodal/SaveForGroupsModal.js";
import InformationModal from "../../../components/shared/informationmodal/InformationModal.js";
import {DeviceParameterContent} from "../../../components/parameters/DeviceParameterContent.js";
import {
    findNodeInHierarchyById,
    initTechnicalParamsState,
    updateTechnicalParamsState
} from "../../../helpers/reduxstore/reducers/technicalParamsReducer.js";
import useInterval from "../../../helpers/hooks/useInterval.js";
import {roleConstants} from "../../../helpers/constants.js";
import {loadCurrentDevice} from "../../../helpers/reduxstore/reducers/deviceReducer.js";
import {isEqual} from "lodash";
import {loadCurrentDeviceWrapper} from "../../../helpers/deviceHelper.js";
import {IfDeviceFound} from "../../../components/dashboard/IfDeviceFound.js";
import {useMsal} from "@azure/msal-react";
import {hasAnyRole} from "../../../helpers/authHelper.js";
import {ParametersTreeView} from "./ParametersTreeView.js";
import {DevicePagesTopInformation} from "../technical/DevicePagesTopInformation.js";

//TODO: delete once firmware updates has been done
/** Displays the parameters page for an individual device */
export default function OldDeviceParametersPage() {
    const {t} = useTranslation(['parameterspage', 'common', 'settingspage'])
    const dispatch = useDispatch()
    const {deviceSerialNumber} = useParams();
    const {accounts} = useMsal();

    const {hierarchyList, selectedParameterId} = useSelector((state) => state.technicalParams)
    const {currentDevice: device} = useSelector(g => g.device)

    const [selectedParam, setSelectedParam] = useState(null)

    const cachedIsAware = () => hierarchyList[0].id === 1000

    useEffect(() => {
        if (hierarchyList.length === 0 || cachedIsAware()) {
            dispatch(initTechnicalParamsState(getDeviceParamsStateStructure()))
        }

        // small hack here to detect if user has changed role to technical, in which case some nodes should be hidden now.
        // This almost never happens probably, but confuses support who changes roles more often.
        if (hierarchyList && hasAnyRole(accounts, [roleConstants.TechnicalBasic, roleConstants.Technical]) && document.querySelector("#b67405be-ef70-4e44-8167-1245b1c170c8")) {
            console.warn("User is now technical, so hiding some previously visible nodes")
            dispatch(initTechnicalParamsState(getDeviceParamsStateStructure()))
        }

        dispatch(updateTechnicalParamsState({device: device, t}))
    }, [device])

    useEffect(() => {
        let foundNode = findNodeInHierarchyById(hierarchyList, selectedParameterId)?.data;
        // only update selected parameter if anything new is found, so value is not reset every 5 seconds
        if (!isEqual(foundNode, selectedParam) || selectedParam == null) {
            setSelectedParam(foundNode)
        }
    }, [device, hierarchyList, selectedParameterId])

    useInterval(() => {
        loadCurrentDeviceWrapper(device, device.serial_number, dispatch)
    }, 5000)

    const [stagedConfiguration, setStagedConfiguration] = useState({})
    const [showGroupModal, setShowGroupModal] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [selectedDeviceIds, setSelectedDeviceIds] = useState([])
    const [selectedGroupIds, setSelectedGroupIds] = useState([])

    const promptForSave = (settings, saveForGroup) => {
        setStagedConfiguration(settings)
        if (saveForGroup) {
            setSelectedDeviceIds([])
            setSelectedGroupIds([])
            setShowGroupModal(true)
        } else setShowConfirmation(true)
    }
    const cancelConfiguration = () => {
        setSelectedDeviceIds([device.id])
        setStagedConfiguration({})
        setShowGroupModal(false)
        setShowConfirmation(false)
    }
    const commitChanges = async () => {
        if (stagedConfiguration) {
            if (selectedDeviceIds.length === 0 && selectedGroupIds.length === 0) {
                await dispatch(saveConfigurationByDeviceList(stagedConfiguration, [device.id], !isAwareDevice(device.serial_number)));
            } else if (selectedDeviceIds.length !== 0) {
                await dispatch(saveConfigurationByDeviceList(stagedConfiguration, selectedDeviceIds, !isAwareDevice(device.serial_number)));
            } else {
                await dispatch(saveConfigurationByGroupList(stagedConfiguration, selectedGroupIds, !isAwareDevice(device.serial_number)));
            }
            await dispatch(loadCurrentDevice(device.serial_number));
        }
        setShowGroupModal(false)
        setShowConfirmation(false)
    }

    if (!device.connected || !AnyReportedParameters(device)) return <div className='d-flex flex-column flex-grow-1'>
        <DevicePagesTopInformation device={device}/>
        <NavTopBar deviceSerialNumber={deviceSerialNumber} currentPage='parameters'/>
        <div className='d-flex flex-grow-1 bg-white rounded-3 p-3 mb-3'>
            {!device.connected ? t('settingspage:settings_not_available_device_offline') : t('settingspage:settings_not_available_no_parameters')}
        </div>
    </div>

    return (
        <IfDeviceFound>
            {/*=== Modals ===*/}
            <SaveForGroupsModal show={showGroupModal} dismiss={cancelConfiguration}
                                setSelectedGroupIds={setSelectedGroupIds}
                                accept={commitChanges}/>
            <InformationModal show={showConfirmation} title={t('settingspage:confirm_modal.title')}
                              body={t('settingspage:confirm_modal.body')}
                              dismiss={cancelConfiguration} accept={commitChanges}/>

            <div className='d-flex flex-column flex-grow-1'>
                <DevicePagesTopInformation device={device}/>
                <NavTopBar deviceSerialNumber={deviceSerialNumber} currentPage='parameters'></NavTopBar>

                <div className="d-block d-md-flex">
                    <ParametersTreeView hierarchyList={hierarchyList} selectedParameterId={selectedParameterId}/>
                    <DeviceParameterContent selectedParameter={selectedParam} device={device} promptForSave={promptForSave}/>
                </div>

            </div>
        </IfDeviceFound>
    )
}

